
import { Component, Vue, Provide, Emit } from 'vue-property-decorator'
import { mobileRegExp } from '@/utils/reg'
import InputItem from '@/components/InputItem.vue'
import VerificationCode from '@/components/VerificationCode.vue'
import BindPhoneVerificationCode from '@/components/bindphone/VerificationCode.vue'
import getCurrentQuery from '@/utils/querystrings'
import GlobalMixin from '@/mixins/global'
import { getGoBackUrl, removeGoBackUrl } from '@/utils/gobackurl'
import { isMiniProgram, isWechat } from '@/utils/env'

@Component({
  name: 'passportBindPhone',
  components: {
    'input-item': InputItem,
    'verification-code': VerificationCode,
    'bp-verification-code': BindPhoneVerificationCode // 已登录状态绑定安全手机（来福隐私商城可以用用户名和密码登录会导致用户没有绑定安全手机）
  },
  mixins: [GlobalMixin]
})
export default class PassportBindPhonePage extends Vue {
  @Provide() public isFirst: boolean = true
  @Provide() public mobile: string = ''
  @Provide() public apiError: string = ''
  @Provide() public smsType: number = 3
  @Provide() public grantType: number = 4
  @Provide() public isLogin: number = 0 // 是否已经登录状态，已登录状态的走bp-verification-code
  @Provide() public isFromLife: boolean = false // 是否来自来福商城
  @Provide() public isFromPublic: boolean = false // 是否来自M站
  @Provide() public isInWechat: boolean = isWechat // 是否在微信域内

  public created () {
    this.smsType = parseInt(getCurrentQuery('smstype')) || 3
    this.grantType = parseInt(getCurrentQuery('grantType')) || 4
    this.isLogin = parseInt(getCurrentQuery('isLogin')) || 0
    const from = getCurrentQuery('from') || ''
    this.isFromLife = from === 'life'
    this.isFromPublic = from === 'public'
  }
  @Emit()
  public async sendCode () {
    this.apiError = this.mobileError()
    if (this.apiError) {
      return
    }
    this.isFirst = false
    setTimeout(() => {
      this.isLogin ? (this.$refs.bindPhoneVerification as any).sendCode() : (this.$refs.verification as any).sendCode()
    }, 0)
  }
  @Emit()
  public mobileError () {
    if (!this.mobile) {
      return '手机号码不能为空'
    }
    return mobileRegExp.test(this.mobile) ? '' : '手机号格式不正确，请重新输入'
  }

  @Emit()
  public goback () {
    if (isMiniProgram) {
      const wx = (window as any).wx
      wx.miniProgram.navigateBack({
        delta: 1,
        fail: () => {
          wx.miniProgram.switchTab({
            url: 'pages/index/index'
          })
        }
      })
      return
    }
    let backUrl = getGoBackUrl()
    if (!this.isFirst) {
      this.isFirst = true
    } else if (backUrl) {
      removeGoBackUrl()
      location.replace(backUrl)
    } else {
      this.$router.replace('/sms')
    }
  }

  @Emit()
  public setValue (detail: any) {
    this.mobile = detail.value
  }
}
